<template>
  <el-dialog
    :title="title"
    :model-value="modelValue"
    width="1200px"
    draggable
    destroy-on-close
    :z-index="2010"
    @close="closed"
  >
    <div class="news-create-container">
      <el-form
        ref="ruleFormRef"
        style="flex: 2"
        :model="form"
        :rules="formRules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="15">
            <el-form-item label="回复内容" prop="content" required>
              <el-input
                v-model.trim="form.content"
                placeholder="请输入"
                :rows="5"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="图片">
              <UploadImage
                v-model="form.imgUrl"
                width="200px"
                height="200px"
                format="array"
              ></UploadImage>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed, onMounted } from 'vue'
import * as messageApi from '@/api/message.js'
import { ElMessage } from 'element-plus'
import UploadImage from '@/components/UploadImage/index.vue'
import { rules } from '../data.js'
import _ from 'lodash'

const props = defineProps({
  modelValue: {
    type: Boolean,
    require: true
  },
  info: {
    type: Object
  },
  commentId: {
    type: String,
    default: ''
  },
  openid: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['update:modelValue', 'updateClient'])

// 判断是否有参数传递过来
// const isInfoFlag = computed(() => {
//   return JSON.stringify(props.info) === '{}'
// })

// 标题
const title = computed(() => {
  return JSON.stringify(props.info) === '{}' ? '创建' : '修改'
})

// 数据源
const form = ref({
  content: '',
  imgUrl: []
})
// 取消
const closed = () => {
  form.value = {
    content: '',
    imgUrl: []
  }
  emits('update:modelValue', false)
}

watch(
  () => form.value.imgUrl,
  val => {
    // form.value.banner = val ? val[0].img : ''
  }
)

// 提交事件
const submitLoading = ref(false)
const ruleFormRef = ref(null)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (!valid) {
      ElMessage.error(fields[Object.keys(fields)[0]][0].message)
      return
    }

    submitLoading.value = true
    const params = {
      ...form.value,
      imgUrl:
        form.value.imgUrl.length > 0
          ? _.pick(form.value, ['imgUrl']).imgUrl[0].img
          : '',
      comment_id: props.commentId,
      openid: props.openid,
      type: 0
    }
    try {
      await messageApi.addReply(params)
      ElMessage.success('更新成功')
      emits('updateClient')
      closed()
    } finally {
      submitLoading.value = false
    }
  })
}

/* // 获取数据方法
const loading = ref(false)
const getDetail = async () => {
  loading.value = true
  try {
    form.value = JSON.parse(JSON.stringify(props.info))
  } catch (error) {
    console.log('error :>> ', error)
  } finally {
    loading.value = false
  }
}
watch(
  [() => props.info, () => props.modelValue],
  async ([infoVal, modelValueVal]) => {
    if (JSON.stringify(infoVal) !== '{}') getDetail()
  }
) */

const formRules = rules
</script>

<style lang="scss" scoped>
.news-create-container {
  display: flex;
  ::v-deep .el-form {
    flex: 1;
  }
}
.photo-container {
  width: 255px;
  margin-left: 10px;
}
</style>
