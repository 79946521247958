<template>
  <div class="user-manage-container">
    <el-card class="header">
      <!-- <search @search="searchClick" @clear="clearClick"></search> -->
      <div v-if="parentData.content" class="message-box">
        {{ parentData.content }}
        <el-image
          style="width: 100px; height: 100px; border-radius: 6px"
          :src="parentData.imgUrl"
          :fit="'contain'"
          :preview-src-list="[parentData.imgUrl]"
          :preview-teleported="true"
        />
      </div>
    </el-card>
    <el-card>
      <div class="table-head-box">
        <el-button
          style="float: right"
          :icon="Refresh"
          size="small"
          round
          @click="getListData"
        ></el-button>
        <el-button
          v-permission="['addUser']"
          class="button"
          type="primary"
          size="small"
          @click="handleCreate"
        >
          回复
        </el-button>
      </div>

      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="id"
          label="ID"
          width="150"
          align="center"
          fixed="left"
        />
        <el-table-column
          prop="content"
          label="内容"
          min-width="250"
          align="center"
          fixed="left"
          show-overflow-tooltip
        />
        <el-table-column label="图片" width="150" align="center">
          <template #default="{ row }">
            <el-image
              style="width: 100px; height: 100px; border-radius: 6px"
              :src="row.imgUrl"
              :fit="'contain'"
              :preview-src-list="[row.imgUrl]"
              :preview-teleported="true"
            />
          </template>
        </el-table-column>

        <el-table-column label="类型" min-width="180">
          <template #default="{ row }">
            <el-tag
              v-if="row.type !== null && row.type !== undefined"
              :type="row.type === 1 ? '' : 'warning'"
              effect="dark"
            >
              {{ row.type === 1 ? '用户回复' : '客服回复' }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" min-width="180">
          <template #default="{ row }">
            {{ $filters.dateTimeFilter(row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="170">
          <template #default="{row}">
            <el-button
              v-permission="['replyMessage']"
              type="primary"
              size="small"
              @click="handleUpdate(row)"
            >
              回复
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20, 50, 100, 500, 1000]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <create-dialog
      v-model="createDialogVisible"
      :openid="parentData.openid"
      :commentId="props.id"
      @updateClient="getListData"
    ></create-dialog>
  </div>
</template>

<script setup>
import { ref, onActivated, watch, defineProps, computed } from 'vue'
import { useStore } from 'vuex'
import * as messageApi from '@/api/message.js'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import { Refresh } from '@element-plus/icons-vue'
import search from './components/search.vue'
import CreateDialog from './components/create.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  data: {
    type: String,
    default: ''
  }
})

const parentData = computed(() => {
  let obj = {}
  if (props.data) {
    obj = JSON.parse(decodeURIComponent(props.data))
  }
  return obj
})

// 数据相关
const tableData = ref([])
const multipleTable = ref(null)
const total = ref(0)
const page = ref(1)
const size = ref(10)
const searchFrom = ref({})
const loading = ref(false)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await messageApi.messageReplyList({
    current: page.value,
    size: size.value,
    mainId: props.id,
    ...Object.fromEntries(
      Object.entries(searchFrom.value).filter(
        item => item[1] !== '' || item[1] === 0
      )
    )
  })
  tableData.value = result.items
  total.value = result.total
  loading.value = false
}

// 处理导入用户后数据不重新加载的问题
onActivated(() => {
  getListData()
})

const selectedIndexs = ref([])
const handleSelectionChange = e => {
  selectedIndexs.value = e
}

// 新增点击事件
const createDialogVisible = ref(false)
const handleCreate = () => {
  createDialogVisible.value = true
}
// 修改点击事件
const editSelectClient = ref({})
const handleUpdate = row => {
  editSelectClient.value = row
  createDialogVisible.value = true
}
watch(createDialogVisible, val => {
  if (!val) editSelectClient.value = {}
})

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

// 搜索
const searchClick = e => {
  page.value = 1
  searchFrom.value = e
  getListData()
}
// 清空
const clearClick = () => {
  page.value = 1
  searchFrom.value = {}
  getListData()
}
</script>

<style lang="scss" scoped>
.user-manage-container {
  .header {
    margin-bottom: 22px;
    text-align: right;
  }
  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  ::v-deep .el-tag {
    margin-right: 6px;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}

.message-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
